:root {
  /* --main-bg-color: rgba(70, 130, 180, 0.3); */
  /* --main-bg-color: rgba(150, 150, 150, 0.5); */
  /* --main-bg-color: rgba(200, 200, 200, 0.3); */
  --main-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  /* --main-bg-img: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(249, 232, 51, 0.5) 0%,
    rgba(250, 196, 59, 0.5) 100.2%
  ); */

  --main-bg-img: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(249, 232, 51, 0.5) 0%,
    rgba(220, 220, 200, 0.3) 100%
  );
  /* --main-bg-img: linear-gradient(
    135deg,
    rgba(89, 56, 160, 0.5) 10%,
    rgba(188, 120, 236, 0.5) 100%
  ); */

  /* --main-bg-img: linear-gradient(
    19deg,
    rgba(244, 221, 16, 0.7) 0%,
    rgba(221, 214, 243, 0.2) 100%
  ); */

  /* FUN ONE */
  --caution-bg-img: repeating-linear-gradient(
    45deg,
    rgba(255, 233, 36, 0.3),
    rgba(255, 233, 36, 0.3) 20px,
    rgba(20, 20, 20, 0.3) 20px,
    rgba(20, 20, 20, 0.3) 40px
  );

  --caution-bg-img-2: repeating-linear-gradient(
    45deg,
    rgba(20, 20, 20, 0.3),
    rgba(20, 20, 20, 0.3) 20px,
    rgba(255, 233, 36, 0.3) 20px,
    rgba(255, 233, 36, 0.3) 40px
  );
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html body {
  height: auto;
  width: 100vw;
  font-size: 16px;
  background-image: url("../public/prism.png");
  font-family: "Montserrat", sans-serif;
  overflow: auto;
  overflow-x: hidden;
}

.hidden {
  display: none;
}

.siteContainer {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.synLogo {
  height: 50px;
  width: auto;
}

.discordContainer {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.offcanvas {
  z-index: 1000;
  background: rgba(255, 255, 255, 0);
  border: none;

  /* background-image: url("../public/SC_Poster_.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; */
}

.offcanvas-backdrop {
  z-index: 500;
  background: rgba(0, 0, 0, 0);
}

.discordiframe {
  z-index: 9000;
}

.downloadBlurb {
  width: 80%;
  background: rgb(30, 30, 30);
  color: rgb(180, 180, 180);
  padding: 1em;
  border-radius: 4px;
}

.discordButton {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 8px;
  right: 5px;
  padding: 8px;
  color: rgba(250, 250, 250, 1);
  background: rgba(114, 137, 218, 1);
  border-radius: 50%;
  transition: 200ms ease-in-out;
  z-index: 10;
}

.discordButton:active {
  background: rgba(114, 137, 218, 0.5);
}

.closeBlurb {
  width: 100%;
  height: auto;
  background: rgba(230, 230, 230, 0.3);
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.5em 5px 0 5px;
}

.closeBlurb p {
  margin-bottom: 0;
}

.navbar {
  height: auto;
  background: var(--main-bg-color);
  background-image: var(--main-bg-img);
  color: white;
  display: grid;
  grid-template-columns: 0.1fr 1fr 1fr;
  padding: 0.5em 1em 0.5em 0.5em;
}

.appContainer {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgba(0, 0, 0, 1);
  margin-top: 1em;
}

.titleText {
  font-size: 2em;
  height: 100%;
  padding-top: 5px;
  margin-bottom: 0;
}

.navList {
  width: 80%;
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0;
}

.navItem {
  color: black;
  font-size: 1.5em;
  background: rgba(200, 200, 200, 0.4);
  padding: 0.2em 0.5em;
  cursor: pointer;
  border-radius: 8px;
  transition: 100ms ease-in-out;
}

.navActive {
  background: rgba(200, 200, 200, 0.8);
  transform: scale(1.1);
}

.componentWrapper {
  width: 80vw;
  height: auto;
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 0;
}

.output-title-container {
  margin-bottom: 150px;
}

.priceToolWrapper {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1em;
  overflow-y: auto;
  color: rgba(250, 250, 250, 0.8);
  margin-bottom: 150px;
}

.resetIcon {
  font-size: 3em;
  color: rgba(150, 30, 30, 1);
  font-weight: 700;
  position: absolute;
  top: 5px;
  left: 5px;
  transition: 200ms ease-in-out;
}

.resetIcon:active {
  color: rgba(0, 0, 0, 0.25);
  transform: scale(0.8);
}

.infoIconContainer {
  width: 100%;
  height: fit-content;
  display: absolute;
}

.infoIcon {
  cursor: help;
  position: absolute;
  top: 10px;
  right: 10px;
}

.hiddenInfo {
  position: absolute;
  width: 85%;
  height: fit-content;
  background-color: rgb(68, 68, 68);
  font-size: 1em;
  color: #fff;
  padding: 0.5em;
  border-radius: 6px;
  text-align: left;

  z-index: 1;
  top: 15px;
  right: 10%;
  visibility: hidden;

  opacity: 0;
  transition: opacity 0.3s;
}

.infoIcon:hover + .hiddenInfo {
  display: block;
  visibility: visible;
  opacity: 1;
}

.priceTool-SaddleBagToggle {
  width: 100%;
  height: 4em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  font-size: 1.2em;
}

.priceTool-SaddleBagToggle input {
  width: 20px;
  height: 20px;
  border: none;
}

.priceTool-yieldSelectWrapper {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.priceTool-yieldSelect {
  width: auto;
  height: auto;
  border: none;
  background: rgba(250, 250, 250, 0.7) !important;
  font-size: 0.75em;
  margin-left: 1em;
}

.priceToolSelector {
  width: 50%;
}

.priceTool-InputButtonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  margin: 1em;
}

.priceTool-InputButtonWrapper button {
  margin-left: 1em;
  border: none;
  background: rgba(200, 200, 200, 0.8);
  padding: 8px;
  border-radius: 5px;
  margin: 0.5em;
  font-weight: 500;
  transition: 200ms ease-in-out;
}
.priceTool-InputButtonWrapper button:active {
  background: rgba(200, 200, 200, 0.5);
}
.priceTool-scuListContainer {
  width: 100%;
  margin: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid red; */
}

.priceTool-scuList {
  font-size: 1.2em;
  height: auto;
  width: 70%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 4px 10px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
}

.priceTool-scuInput {
  width: 3em;
  height: 1.8em;
  background-color: rgba(200, 200, 200, 0.5);
  border: none;
  border-radius: 5px;
  text-align: center;
  justify-self: right;
}

.priceTool-outputPriceWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: baseline;
  grid-column: span 2;
  font-size: 1.3em;
}

.priceTool-outputPriceWrapper small {
  font-size: 0.6em;
  opacity: 0.8;
}

.priceTool-totalVal {
  font-size: 3em;
  color: white;
  margin-bottom: 0;
}

.priceTool-totalValContainer {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 1em;
}
.priceTool-totalValContainer small {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.5em;
  opacity: 0.8;
  margin-left: 0.5em;
}

.priceTool-checkContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
  font-size: 1.2em;
}
.priceTool-checkContainer input {
  width: 20px;
  height: 20px;
  border: none;
}

.dashboard {
  width: 35vw;
  height: auto;
  min-height: 20vh;
  background: var(--main-bg-color);
  background-image: var(--main-bg-img);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 1em 0;
  box-shadow: var(--main-box-shadow);
}

.rock-input-dashboard {
  margin-bottom: 150px;
}

.assayBtnContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.assayBtnContainer p {
  font-weight: 500;
  font-size: 1.6em;
  margin: 0;
  margin-top: 20%;
  color: white;
}

.btn-mine {
  height: 1.7em;
  width: 1.7em;
  font-size: 3em;
  /* background: var(--main-bg-color); */
  color: rgba(200, 200, 200, 0.8);
  border-radius: 8px;
  padding: 2px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  background-image: repeating-linear-gradient(
    45deg,
    rgba(255, 233, 36, 0.3),
    rgba(255, 233, 36, 0.3) 1rem,
    rgba(20, 20, 20, 0.3) 1rem,
    rgba(20, 20, 20, 0.3) 2rem
  );
  background-size: 200% 200%;
}

.btn-mine:hover {
  /* background-image: repeating-linear-gradient(
    45deg,
    rgba(255, 233, 36, 0.3),
    rgba(255, 233, 36, 0.3) 1rem,
    rgba(20, 20, 20, 0.3) 1rem,
    rgba(20, 20, 20, 0.3) 2rem
  );
  background-size: 200% 200%;
  animation: bg 2s linear infinite; */
}

.btn-mine:active {
  color: rgba(240, 40, 40, 0.5);

  /* background-image: repeating-linear-gradient(
    45deg,
    rgba(255, 233, 36, 0.3),
    rgba(255, 233, 36, 0.3) 1rem,
    rgba(20, 20, 20, 0.3) 1rem,
    rgba(20, 20, 20, 0.3) 2rem
  );
  background-size: 200% 200%;
  animation: bg 2s linear; */
}

.btn-mine-animation {
  background-image: repeating-linear-gradient(
    45deg,
    rgba(255, 233, 36, 0.3),
    rgba(255, 233, 36, 0.3) 1rem,
    rgba(20, 20, 20, 0.3) 1rem,
    rgba(20, 20, 20, 0.3) 2rem
  );
  background-size: 200% 200%;
  animation: bg 2s linear;
  animation-duration: 1s;
}

.massInputWrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.massInputWrapper label {
  font-size: 1.5em;
  font-weight: 500;
  margin-right: 1em;
}

.massInputField {
  border: none;
  width: 50%;
  /* background: rgba(250, 250, 250, 0.7); */
  background: rgba(200, 200, 200, 0.4);
  height: 1.8em;
  border-radius: 5px;
  font-size: 1.5em;
  text-align: center;
}

.materialListContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 500px;
  /* border: 1px solid blue; */
  padding: 0.5em;
  align-items: center;
}

.bestResultsPara {
  height: 1.3em;
  font-size: 1em;
  color: rgba(20, 20, 20, 0.8);
  margin-bottom: 3px;
  padding: 0;
  white-space: nowrap;
  /* display: flex;
  flex-shrink: 2; */
}

.bestResultsPara p {
  margin-bottom: 0;
}

.singleMaterialContainer {
  width: 80%;
  height: 1.3em;
  display: flex;
  justify-content: space-between;
  font-size: 1.5em;
  padding-left: 1em;
  padding-right: 1.5em;
  background: rgba(200, 200, 200, 0.2);
  align-items: center;
  position: relative;
}

.lineDark {
  background: rgba(200, 200, 200, 0.2);
}

.lineLight {
  background: rgba(200, 200, 200, 0.5);
}

.materialPercentInput {
  width: 2em;
  height: 1em;
  border: none;
  background: rgba(0, 0, 0, 0.1);
}

.tier1 {
  color: rgba(150, 30, 30, 1);
  font-weight: 500;
}

.tier2 {
  color: rgba(30, 120, 30, 1);
  font-weight: 500;
}

.singleMaterialContainer::after {
  content: "%";
  position: absolute;
  right: 5px;
  color: black;
}

.btn-eval-rock {
  margin-top: 1em;
  border: none;
  padding: 5px;
}

select.form-control {
  -webkit-appearance: menulist !important;
  -moz-appearance: menulist !important;
  -ms-appearance: menulist !important;
  -o-appearance: menulist !important;
  appearance: menulist !important;
}

.systemSelect {
  width: auto;
  height: auto;
  border: none;
  background: rgba(250, 250, 250, 0.7) !important;
  margin-bottom: 0.5em;
}

.refineryMethod {
  width: auto;
  height: auto;
  border: none;
  background: rgba(250, 250, 250, 0.7) !important;
  margin-bottom: 0.5em;
}

.outputContainer {
  margin-top: 0.5em;
  height: auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  /* border: 1px solid blue; */
  font-size: 1.5em;
  align-items: flex-end;
}

.refineryOptionsContainer {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1em;
  padding: 0.5em;
}

.select-wrapper-refinery-and-method {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.form-check {
  width: auto;
}

.individualOutputWrapper {
  font-size: 0.9em;
  width: 100%;
  display: grid;
  grid-template-columns: 1.2fr 0.1fr 0.6fr 1fr;
  padding: 0 0.2em;
}

.positive {
  background-image: linear-gradient(
    to left,
    rgba(30, 150, 30, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.negative {
  /* background: rgba(200, 20, 20, 0.2); */
  /* border-right: 5px solid red; */
  background-image: linear-gradient(
    to left,
    rgba(190, 30, 30, 0.4),
    rgba(0, 0, 0, 0)
  );
}

.individualOutputWrapper p {
  height: 100%;
  /* position: absolute;
  left: 0; */
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  font-size: 0.6em;
  font-weight: 400;
  color: black;
}

.positive:before {
}

.negative:before {
}

.resultArrow {
  position: absolute;
  left: 0;
  font-size: 1.5em;
}

.positiveArrow {
  color: forestgreen;
}

.negativeArrow {
  color: crimson;
}

.individualOutputMat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.individualOutputScu {
  text-align: right;
}

.individualOutputScu small {
  font-size: 0.6em;
  opacity: 0.8;
}

.individualOutputVal {
  text-align: right;
}

.individualOutputVal small {
  font-size: 0.6em;
  opacity: 0.8;
}

.refineryRecContainer {
  padding-left: 0.5em;
  margin-top: 0.5em;
  width: 100%;
  font-size: 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}
.refineryRecWrapper {
}
.refineryRecWrapper p {
  margin-bottom: 0;
}

.recTitle {
  font-weight: 500;
}

@media (max-width: 1200px) {
  html body {
    height: auto;
    font-size: 4vw;
    overflow: auto;
  }

  .appContainer {
    height: auto;
    text-align: center;
  }

  .rock-input-dashboard {
    margin-bottom: 0px;
  }

  .synLogo {
    position: absolute;
    height: 100px;
    width: 100px;
    top: 10px;
    left: calc(50% - 50px);
    opacity: 0.3;
    z-index: 1;
  }

  .navbar {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    text-align: center;
  }

  .titleText {
    color: white;
    width: 100%;
    font-size: 10vw;
    position: static;
    padding-bottom: 10px;
    z-index: 10;
  }

  .navList {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .discordButton {
    position: fixed;
    right: 5px;
    top: 90%;
    opacity: 0.8;
  }
  .componentWrapper {
    height: auto;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
  }

  .dashboard {
    width: 90vw;
    height: auto;
  }

  .dashboard h3 {
    position: inherit;
  }

  .massInputField {
    margin: 10px 0;
  }
  .materialListContainer {
    width: auto;
    width: 90%;
  }

  .bestResultsPara {
    font-size: 0.8em;
  }

  .singleMaterialContainer {
    width: 100%;
    height: 10vw;
  }

  .assayBtnContainer p {
    margin-top: 0;
  }

  .assayBtnContainer {
    margin-bottom: 1em;
  }

  .btn-mine:hover,
  .btn-mine:focus,
  /* .btn-mine:active {
    background-image: repeating-linear-gradient(
      45deg,
      rgba(255, 233, 36, 0.3),
      rgba(255, 233, 36, 0.3) 1rem,
      rgba(20, 20, 20, 0.3) 1rem,
      rgba(20, 20, 20, 0.3) 2rem
    );
    background-size: 200% 200%;
    animation: bg 2s linear;
    animation-duration: 1s;
    animation-iteration-count: 1;
  } */

  .outputContainer {
    align-items: center;
    width: 100%;
  }

  .refineryOptionsContainer {
    flex-direction: column;
    align-items: center;
    padding: 0.5em;
  }

  .systemSelect {
    height: auto;
    width: 90%;
    margin-right: 0;
    margin-top: 0.5em;
    font-size: inherit;
  }

  .refineryMethod {
    height: auto;
    width: 90%;
    margin-right: 0;
    margin-top: 0.5em;
    font-size: inherit;
  }

  .individualOutputWrapper {
    /* border: 1px solid red; */
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 0.1fr;
    grid-template-rows: 1fr 1fr;
    padding: 0 0.5em;
  }
  .individualOutputVal {
    text-align: left;
    grid-column: span 2;
  }
  .individualOutputScu {
    text-align: left;
    grid-column: span 2;
  }

  .priceTool-checkContainer {
    margin-top: 0px;
  }

  .priceTool-SaddleBagToggle {
    width: 100%;
    margin-bottom: 0px;
    margin-top: 1.2em;
    /* position: relative; */
  }

  .priceTool-yieldSelect {
    width: auto;
    height: auto;
    border: none;
    background: rgba(250, 250, 250, 0.7) !important;
    font-size: 0.75em;
  }
}

@keyframes bg {
  0% {
    background-position: 0% 100%;
  }
}
